<template>
  <div>
    <PriceTableForm :formData="formState" @formCancel="handleCancel" @formOk="handleOk" />
  </div>
</template>
<script>

import { editPriceTable, getPriceTable } from '../http/api'
import PriceTableForm from '../components/form/PriceTableForm.vue'
export default {
  components: {
    PriceTableForm
  },
  data() {
    return {
      formState: {},
      id: this.$route.query.id || ''
    }
  },
  created() {
    if (this.id) {
      this.getPriceTable()
    }
  },
  methods: {
    getPriceTable() {
      getPriceTable({variables: {id: this.id}}).then(res => {
        let priceTable = res.data.priceTable
        let content = priceTable.content
        let c = content.replace(/<a[^>]*href=['"]([^"]*)['"][^>]*>(.*?)<\/a>/g, (matchStr, groups, con) => {
          if (con.includes('<img')) {
            return con
          } else {
            return matchStr
          }
        })
        priceTable.content = c
        this.formState = JSON.parse(JSON.stringify(priceTable));
      })
    },
    handleOk(e) {
      console.log(e)
      editPriceTable({variables: e}).then(res => {
        console.log(res)
        this.handleCancel()
      })
    },
    handleCancel() {
      this.formState = {}
      this.$router.push('/price-table')
    }
  },
}
</script>
<style lang="sass" scoped>

</style>