<template>
  <a-form
    ref="form"
    :model="formState"
    name="form"
    :validateOnRuleChange="false"
    :label-col="{ span: 2 }"
    :wrapper-col="{ span: 19 }"
    autocomplete="off"
    scrollToFirstError
  >
    <a-form-item
      label="标题"
      name="title"
      :rules="[{ required: true, message: '输入文章标题' }]"
    >
      <a-input v-model:value="formState.title" name="title" />
    </a-form-item>
    <a-form-item
      label="排序"
      name="displayOrder"
    >
      <a-input v-model:value="formState.displayOrder" name="displayOrder" />
    </a-form-item>
    <a-form-item label="文章内容" name="content">
      <Editor ref="editorOne" v-model:value="formState.content"></Editor>
    </a-form-item>
    <a-form-item>
      <a-button @click="handleCancel" style="margin-left:20px">取消</a-button>
      <a-button @click="handleOk" style="margin-left:20px">保存</a-button>
    </a-form-item>
  </a-form>
</template>
<script>
import { ref, watch } from "vue";
import Editor from "../Editor.vue";
export default {
  name: "ArticlesForm",
  components: {
    Editor
  },
  props: {
    formData: {
      type: Object,
      default: () => {return {}},
    },
    products: {
      type: Array,
      default: () => {return []},
    }
  },
  setup(props) {
    const formState = ref(props.formData);
    watch(() => props.formData, (newVal) => {
      formState.value = newVal
    })
    return {
      formState,
      form: ref(null),
      editorOne: ref(null)
    };
  },

  methods: {
    change(e) {
      console.log(e)
    },
    setImg(e, param, multiple) {
      let img = e[0];
      let formState = this.formState;
      if (multiple) {
        if (!formState[param] || !Array.isArray(formState[param]))
          formState[param] = [];
        formState[param] = formState[param].concat(img);
      } else {
        formState[param] = img;
      }
      console.log(formState);
      this.formState = JSON.parse(JSON.stringify(formState));
    },

    removeImg(id, param) {
      console.log(id);
      let formState = this.formState;
      let images = formState[param];
      images.forEach((e, index) => {
        if (e.id == id) {
          images.splice(index, 1);
        }
      });
      formState[param] = images;
      this.formState = JSON.parse(JSON.stringify(formState));
    },

    handleOk() {
      this.form
        .validateFields()
        .then((res) => {
          res.displayOrder = res.displayOrder ? parseInt(res.displayOrder) : 0
          let formState = Object.assign(this.formState, res);
          let valueHtml = this.editorOne.valueHtml
          let v = valueHtml.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/g, (matchStr) => {
            let href = matchStr.match(/\sdata-href=['"](.*?)['"]/)[1]
            if (href) {
              return `<a href="${href}">${matchStr}</a>`
            } else {
              return matchStr
            }
          })
          formState.content = v
          this.$emit("formOk", formState);
        })
        .catch((err) => {
          console.error(err);
          this.$message.error(err.errorFields[0].errors[0]);
        });
    },

    handleCancel() {
      this.$emit("formCancel");
    },
  },
};
</script>